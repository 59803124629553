import { mapActions } from 'vuex'
import { showVueToast } from '@/utils'

export default {
  name: 'register-form-new',
  components: {
    TextField: () => import(/* webpackChunkName: "icon" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Dropdown: () => import(/* webpackChunkName: "DropDown" */ '@/components/dropdown/Dropdown'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Courses: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Courses'),
    Instructor: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Instructor'),
    Certificate: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Certificate')
  },
  data() {
    return {
      email: '',
      name: '',
      noHp: '',
      password: '',
      selectedState: 'student',
      loginMessage: {
        student: 'Register as a student in G2Academy.',
        alliance: 'To create an Alliance account, please click the link below.'
      },
      availableState: ['student', 'alliance'],
      motivationList: ['Getting a job in Tech Industries', 'Switching careers into Tech', 'Gaining new knowledge (upskilling)', 'Prakerja', 'Others'],
      selectedMotivation: ''
    }
  },
  mounted() {
    // const target = this.$route.hash && this.$route.hash.split('#')[1]
    // if (target && this.availableState.includes(target)) {
    //   this.select(target)
    // } else {
    //   this.select('student')
    // }
  },
  computed: {
    utm() {
      return this.$route.query.utm_source || 'Website'
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('auth', ['registerStudent', 'registerPrakerja']),
    toggle() {
      this.$router.push(`/login?ref=${this.$route.query.ref}`)
    },
    select(state) {
      this.selectedState = state
      this.notification = null
      window.location.hash = state
    },
    doRegister() {
      if (this.validateForm()) {
        this.showLoading()
        const payloads = {
          email: this.email,
          name: this.name,
          nohp: this.noHp,
          password: this.password,
          motivation: this.selectedMotivation,
          utm: this.utm.replace('+', '')
        }

        this.registerStudent({
          payloads: payloads
        }).then(
          () => {
            this.refreshInput()
            this.hideLoading()
            this.toggle()
            showVueToast('Registrasi akun anda berhasil!', 'success', 3000)
          },
          (error) => {
            const message = error.response?.data?.errors?.error
            this.hideLoading()
            if (message) {
              showVueToast(message, 'error', 3000)
            }
          }
        )
      }
    },
    refreshInput() {
      this.email = ''
      this.name = ''
      this.noHp = ''
      this.password = ''
    },
    validateForm() {
      // eslint-disable-next-line
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      const pattern = /^[0-9]{10,13}$/
      if (this.name === '') {
        showVueToast('Name is required!', 'error', 3000)
        return false
      } else if (this.email === '') {
        showVueToast('Email is required!', 'error', 3000)
        return false
      } else if (!regex.test(this.email)) {
        showVueToast('Email format is invalid!', 'error', 3000)
        return false
      } else if (this.noHp === '') {
        showVueToast('Phone number is required!', 'error', 3000)
        return false
      } else if (!pattern.test(this.noHp)) {
        showVueToast('Your phone number format is invalid! (ex: 081234567890)', 'error', 3000)
        return false
      } else if (this.password === '') {
        showVueToast('Password is required!', 'error', 3000)
        return false
      } else if (/\s/.test(this.password)) {
        showVueToast('Password must not contain any spaces!', 'error', 3000)
      } else if (this.selectedMotivation === '') {
        showVueToast('Please choose your motivation!', 'error', 3000)
        return false
      } else {
        return true
      }
    }
  }
}
